import { Box, Button, Text } from 'grommet';
import React from 'react';

const buttonStyle = { paddingTop: 8, paddingBottom: 8, paddingLeft: 15, paddingRight: 15, border: 'none' };
const PrimaryButton = ({ icon, onClick, label, background, color = '#fff', disabled, reverse = false, ...rest }) => {

    let style = background ? Object.assign({ background }, buttonStyle) : buttonStyle;
    style = color ? Object.assign({ color }, style) : style;

    return <Button
        disabled={disabled}
        round={{size: 'small'}}
        icon={icon} 
        primary 
        reverse={reverse}
        style={style} 
        onClick={onClick} 
        label={label}
        size='small'
        {...rest}
    />
}

export default PrimaryButton;

const CustomButton = ({ icon, onClick, label, background='#E82C70', color='#fff', size='xsmall' }) => {
    return (
        <Box justify='center' align='center' onClick={onClick}
            round={{size: 'xsmall'}}
            background={background}
            pad='xsmall'
            color={color}
            direction='row'
            gap='xsmall'
        >
            {icon &&
                <Box>{icon}</Box>
            }
            <Text size={size || 'xsmall'} style={{fontWeight: 'bold'}}>{label}</Text>
        </Box>
    )
}

export {
    CustomButton
}