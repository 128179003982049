const axios = require("axios");
const { baseAPI } = require("../config/config.json");
const { getToken } = require("./storage_service");

const buildHeader = () => {
  return {
    Authorization: `Bearer ` + getToken(),
  };
};
module.exports = {
  getReports: (filters) => {
    const params = {};
    if (filters) {
      Object.keys(filters)
        .filter((key) => filters[key] !== undefined)
        .forEach((key, index) => {
          switch (key) {
            case "start_date":
              params["start"] = filters[key];
              break;
            case "end_date":
              params[`end`] = filters[key];
              break;
            case "catalog":
              params[`catalog_name`] = filters[key];
              break;
            case "partner":
              params[`partner_id`] = filters[key];
              break;
            default:
              params[`${key}`] = filters[key];
              break;
          }
        });
    }
    return axios.get(`${baseAPI}/api/admin/dashboard`, {
      headers: buildHeader(),
      params,
    });
  },
};
