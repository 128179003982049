const axios = require('axios');
const { baseAPI } = require('../config/config.json');
const { getToken, getUser } = require('./storage_service');

const buildHeader = () => {
    return ({
        'Authorization': `Bearer ` + getToken(),
    })
}
module.exports = {
    createPartner: (partner) => axios.post(`${baseAPI}/api/partners`, { data: partner }, { headers: buildHeader() }),
    getPartners: (query, page = 1, pageSize = 25) => {
        const params = {
            _q: query,
            sort: 'name:ASC'
        }
        params['pagination[page]'] = page;
        params['pagination[pageSize]'] = pageSize;

        params["fields[0]"] = "id";
        params["fields[1]"] = "name";
        params["fields[2]"] = "contact";
        params["fields[3]"] = "hubrise_id";
        params["fields[4]"] = "status";
        params["fields[5]"] = "app_connected_at";
        return axios.get(`${baseAPI}/api/partners`, { headers: buildHeader(), params });
    },
    getMyPartners: () => {
        const params = {
            'filters[$and][0][account][id][$eq]': getUser().id
        }
        return axios.get(`${baseAPI}/api/partners`, { headers: buildHeader(), params });
    },
    acceptOrder: (order) => {
        if(order.platform === 'DELIVEROO'){
            return axios.post(`${baseAPI}/api/deliveroo/accept`, { order_id: order.deliveroo_id }, { headers: buildHeader() });;
        }else{
            const id = order.extra_field?.meta?.id;
            return axios.post(`${baseAPI}/api/uber/accept`, { order_id: id }, { headers: buildHeader() });
        }
    },
    denyOrder: (order) => {
        if(order.platform === 'DELIVEROO'){
            return axios.post(`${baseAPI}/api/deliveroo/deny`, { order_id: order.deliveroo_id }, { headers: buildHeader() });;
        }else{
            return axios.post(`${baseAPI}/api/uber/deny`, { order_id: order.object_id }, { headers: buildHeader() });
        }
    },

    updateDeliveryOrder: (order, status) => {
        if(order.platform === 'DELIVEROO'){
            return axios.post(`${baseAPI}/api/deliveroo/delivery`, { order_id: order.deliveroo_id, status }, { headers: buildHeader() });;
        }else{
            return axios.post(`${baseAPI}/api/uber/delivery`, { order_id: order.object_id, status }, { headers: buildHeader() });
        }
    },
    updateStatus: (id, status) => axios.patch(`${baseAPI}/api/partners/${id}/status`, {status}, { headers: buildHeader() }),
    getPartner: (id) => axios.get(`${baseAPI}/api/partners/${id}?populate=*`, { headers: buildHeader() }),
    savePartner: (partner) => {
        return partner.id ?
            axios.put(`${baseAPI}/api/partners/${partner.id}?populate=*`, { data: partner }, { headers: buildHeader() }) :
            axios.post(`${baseAPI}/api/partners?populate=*`, { data: partner }, { headers: buildHeader() })
    },
    saveAccount: (partnerId, account) => {
        return axios.put(`${baseAPI}/api/partners/${partnerId}/account`, account, { headers: buildHeader() })
    },
    deletePartner: id => axios.delete(`${baseAPI}/api/custom/partners/${id}`, { headers: buildHeader() }),
    pushOpeningHours: id => axios.patch(`${baseAPI}/api/partners/${id}/opening-hours`, {}, { headers: buildHeader() }),
    createTestOrder: id => axios.post(`${baseAPI}/api/custom/orders/test-order`, {partnerId: id}, { headers: buildHeader() }),
}