import { Box, Card, DateInput, Image, Select, Text, TextInput } from "grommet";
import React, { useState } from "react";

import moment from "moment";
import { EventEmitter } from "../../components/elements/event_emitter";
import FormField from "../../components/elements/form_field";
import PrimaryButton from "../../components/elements/primary_button";
import StandardText, { COLOR } from "../../components/elements/standard_text";
import LineChart from "../../components/line-chart";
import LoadingAnimation from "../../components/loading_animation";
import NavigationFrame from "../../components/nav_frame";
import deliveroo from "../../images/deliveroo.png";
import uber from "../../images/uber.png";
import { searchCatalog } from "../../service/catalog_service";
import { getReports } from "../../service/dashboard_service";
import { getPartners } from "../../service/partner_service";
import storageService from "../../service/storage_service";
import { toCurrency } from "../../service/utils";

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: "short",
  day: "numeric",
});
const DASHBOARD_PASSWORD = "U^W8jQ$O&ucpF7gP";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      selectedOrder: undefined,
      partners: [],
      catalogs: [],
      selectedPartner: undefined,
      loading: false,
      filters: {
        partner: undefined,
        status: undefined,
        start_date: moment()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ss.000Z"),
        end_date: moment().endOf("date").format("YYYY-MM-DDTHH:mm:ss.000Z"),
      },
      saleReport: undefined,
      orderReport: undefined,
      password: storageService.getDashboardPassword(),
    };
  }

  componentDidMount = () => {
    if (this.state.password === DASHBOARD_PASSWORD) {
      this.loadPageContent();
    }
  };

  loadPageContent = () => {
    this.initReport();
    this.onPartnersSearch();
    this.onCatalogsSearch();
  };

  initReport = () => {
    this.setState({ loading: "Loading reports ..." });
    getReports(this.state.filters)
      .then((res) => {
        this.setState(
          {
            data: res.data,
            loading: false,
          },
          () => {
            this.processOrderReports();
            this.processSalesReports();
          }
        );
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };

  onPartnersSearch = (query) => {
    let timerId = this.state.timerId;
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      getPartners(query).then((res) => {
        this.setState({ partners: res.data.data });
      });
    }, 350);
    this.setState({ timerId });
  };

  onCatalogsSearch = (query) => {
    let timerId = this.state.timerId;
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      searchCatalog({ query }).then((res) => {
        this.setState({ catalogs: res.data.data });
      });
    }, 350);
    this.setState({ timerId });
  };

  processOrderReports = () => {
    const obj = {};
    const platform = { uber: 0, deliveroo: 0 };
    this.state.data.forEach((order) => {
      let label = moment(order.placed_at).format("DD-MM");
      obj[label] = (obj[label] || 0) + 1;
      if (order.platform === "UBER_EAT") {
        platform.uber++;
      } else {
        platform.deliveroo++;
      }
    });
    const data = Object.keys(obj).map((k) => ({ x: k, y: obj[k] }));
    data.sort((d1, d2) => (d1.x > d2.x ? 1 : -1));
    const total = data.map((d) => d.y).reduce((a, s) => a + s, 0);
    const orderReport = {
      total,
      title: "Total orders: " + total,
      uber: ((platform.uber / total) * 100).toFixed(2),
      deliveroo: ((platform.deliveroo / total) * 100).toFixed(2),
      platform,
      graph: {
        labels: data.map((d) => d.x),
        datasets: [
          {
            label: "Orders",
            data: data.map((d) => d.y),
            borderColor: COLOR.green,
            backgroundColor: COLOR.green,
            lineTension: 0.2,
            pointRadius: 5,
            pointBackgroundColor: COLOR.background,
          },
        ],
      },
    };
    this.setState({ orderReport });
  };
  onFilterChange = (field, value) => {
    const filters = this.state.filters;
    filters[field] = value;
    if (!value) filters[field] = undefined;
    this.setState(
      {
        data: [],
        filters: { ...filters },
        pagination: { page: 1, pageSize: 25, total: 25 },
      },
      this.initReport
    );
  };

  processSalesReports = () => {
    const obj = {};
    const platform = { uber: 0, deliveroo: 0 };
    this.state.data.forEach((order) => {
      let label = moment(order.placed_at).format("DD-MM");
      const total = parseFloat(
        order.total
          .replace(",", ".")
          .replace(" EUR", "")
          .replace(" €", "")
          .trim()
      );
      console.log(total);
      obj[label] = parseFloat(((obj[label] || 0) + total).toFixed(2));
      if (order.platform === "UBER_EAT") {
        platform.uber += total;
      } else {
        platform.deliveroo += total;
      }
    });
    const data = Object.keys(obj).map((k) => ({ x: k, y: obj[k] }));
    data.sort((d1, d2) => (d1.x > d2.x ? 1 : -1));
    const total = toCurrency(data.map((d) => d.y).reduce((a, s) => a + s, 0));
    const value = data.map((d) => d.y).reduce((a, s) => a + s, 0);
    const saleReport = {
      title: "Total sales: " + total,
      uber: ((platform.uber / value) * 100).toFixed(2),
      deliveroo: ((platform.deliveroo / value) * 100).toFixed(2),
      platform,
      total,
      graph: {
        labels: data.map((d) => d.x),
        datasets: [
          {
            label: "Sales",
            data: data.map((d) => d.y),
            borderColor: COLOR.green,
            backgroundColor: COLOR.green,
            lineTension: 0.2,
            pointRadius: 5,
            pointBackgroundColor: COLOR.background,
          },
        ],
      },
    };
    this.setState({ saleReport });
  };

  loginDashboard = () => {
    this.setState({password: DASHBOARD_PASSWORD});
    this.loadPageContent();
    storageService.setDashboardPassword(DASHBOARD_PASSWORD);
  }
  render() {
    return (
      <NavigationFrame>
        <Box
          width={"full"}
          justify="center"
          align="center"
          direction="column"
          overflow={"auto"}
        >
          {this.state.data ? (
            <Box
              width={"full"}
              justify="center"
              align="center"
              direction="column"
              gap="medium"
              overflow={"auto"}
              pad={"small"}
              style={{ paddingBottom: 100, minHeight: "auto" }}
            >
              <Box
                justify="center"
                width={"full"}
                pad={{ vertical: "small" }}
                direction="row"
                style={{ minHeight: "auto" }}
              >
                <Box direction="row" gap="small" style={{ minHeight: "auto" }}>
                  <FormField
                    label={"Partner"}
                    size="small"
                    component={
                      <Select
                        placeholder="All partners"
                        size="small"
                        labelKey="name"
                        valueKey={{ key: "id", reduce: true }}
                        onSearch={(text) => this.onPartnersSearch(text)}
                        options={this.state.partners}
                        onChange={({ value: nextValue }) => {
                          this.onFilterChange("partner", nextValue);
                        }}
                        multiple={false}
                        clear={{ position: "bottom" }}
                      />
                    }
                  />
                  <FormField
                    label={"Catalogs"}
                    size="small"
                    component={
                      <Select
                        placeholder="All catalogs"
                        size="small"
                        labelKey="name"
                        valueKey={{ key: "id", reduce: true }}
                        onSearch={(text) => this.onCatalogsSearch(text)}
                        options={this.state.catalogs}
                        onChange={({ value: nextValue }) => {
                          this.onFilterChange("catalog", nextValue);
                        }}
                        clear={{ position: "bottom" }}
                        multiple={false}
                      />
                    }
                  />
                  <FormField
                    label={"Platform"}
                    size="small"
                    component={
                      <Select
                        placeholder="All Platforms"
                        size="small"
                        options={["UBER_EAT", "DELIVEROO"]}
                        onChange={({ option }) => {
                          this.onFilterChange("platform", option);
                        }}
                        clear={{ position: "bottom" }}
                      />
                    }
                  />
                  <FormField
                    label={"Status"}
                    size="small"
                    component={
                      <Select
                        placeholder="All status"
                        size="small"
                        options={[
                          "NEW",
                          "ACCEPTED",
                          "COMPLETED",
                          "REJECTED",
                          "CANCELED",
                        ]}
                        multiple={true}
                        onChange={({ value: nextValue }) => this.onFilterChange("status", nextValue)}
                        clear={{ position: "bottom" }}
                      />
                    }
                  />
                  <FormField
                    label={"From / To"}
                    size="small"
                    component={
                      <DateInput
                        size="small"
                        value={[
                          this.state.filters.start_date,
                          this.state.filters.end_date,
                        ]}
                        buttonProps={{
                          label: (
                            <StandardText
                              bold
                              label={`${dateFormat.format(
                                new Date(this.state.filters.start_date)
                              )} - ${dateFormat.format(
                                new Date(this.state.filters.end_date)
                              )}`}
                            />
                          ),
                        }}
                        onChange={(event) => {
                          const nextValue = event.value;
                          const filters = this.state.filters;
                          this.setState(
                            {
                              data: [],
                              pagination: { page: 1, pageSize: 25, total: 25 },
                              filters: {
                                ...filters,
                                start_date: nextValue[0],
                                end_date: nextValue[1],
                              },
                            },
                            this.initReport
                          );
                        }}
                      />
                    }
                  />
                </Box>
              </Box>

              <Box
                width={"full"}
                justify="center"
                align="center"
                gap="medium"
                direction="row"
                style={{ minHeight: "auto" }}
              >
                <Card
                  pad={"small"}
                  justify="center"
                  align="center"
                  width={"medium"}
                >
                  <Line
                    label={"Orders:"}
                    title={this.state.orderReport?.total}
                  />
                  <Line
                    icon={uber}
                    title={`${this.state.orderReport?.platform?.uber} (${this.state.orderReport?.uber} %)`}
                  />
                  <Line
                    icon={deliveroo}
                    title={`${this.state.orderReport?.platform?.deliveroo} (${this.state.orderReport?.deliveroo} %)`}
                  />
                </Card>
                <Card
                  pad={"small"}
                  justify="center"
                  align="center"
                  width={"medium"}
                >
                  <Line label={"Sales:"} title={this.state.saleReport?.total} />
                  <Line
                    icon={uber}
                    title={`${toCurrency(
                      this.state.saleReport?.platform?.uber
                    )} (${this.state.saleReport?.uber} %)`}
                  />
                  <Line
                    icon={deliveroo}
                    title={`${toCurrency(
                      this.state.saleReport?.platform?.deliveroo
                    )} (${this.state.saleReport?.deliveroo} %)`}
                  />
                </Card>
              </Box>
              <Box
                width={"full"}
                justify="center"
                align="center"
                gap="medium"
                style={{ minHeight: "auto" }}
              >
                <Card
                  width={"xlarge"}
                  justify="center"
                  align="center"
                  pad={"small"}
                  key={1}
                  style={{
                    position: "relative",
                    margin: "auto",
                    width: "60vw",
                  }}
                >
                  {this.state.orderReport && (
                    <OrdersReport
                      data={this.state.orderReport.graph}
                      title={this.state.orderReport.title}
                    />
                  )}
                </Card>
              </Box>
              <Box
                width={"full"}
                justify="center"
                align="center"
                gap="medium"
                style={{ minHeight: "auto" }}
              >
                <Card
                  width={"xlarge"}
                  justify="center"
                  align="center"
                  pad={"small"}
                  key={1}
                  style={{
                    position: "relative",
                    margin: "auto",
                    width: "60vw",
                  }}
                >
                  {this.state.saleReport && (
                    <SalesReport
                      data={this.state.saleReport.graph}
                      title={this.state.saleReport.title}
                    />
                  )}
                </Card>
              </Box>
            </Box>
          ) : (
            <LoadingAnimation />
          )}

          {this.state.password !== DASHBOARD_PASSWORD && (
            <PasswordProtected 
              onLogined={this.loginDashboard}
            />
          )}
        </Box>
      </NavigationFrame>
    );
  }
}

const OrdersReport = ({ data, title }) => {
  return (
    <CardContent title={"Total orders"}>
      <LineChart data={data} title={title} />
    </CardContent>
  );
};

const SalesReport = ({ data, title }) => {
  return (
    <CardContent title={"Sales"} sty>
      <LineChart data={data} title={title} />
    </CardContent>
  );
};

const CardContent = ({ title, children }) => (
  <Box width={"full"} pad={"xsmall"} style={{ minHeight: "auto" }}>
    <Box width={"full"}>
      <StandardText size="large" label={title} bold />
    </Box>
    <Box width={"full"} align="center" justify="center">
      {children}
    </Box>
  </Box>
);

const Line = ({ label, title, icon }) => (
  <Box direction="row" align="center">
    {icon && <Image src={icon} style={{ height: 30 }} />}
    <StandardText label={label} />
    &nbsp; &nbsp;
    <StandardText bold label={title} />
  </Box>
);

const PasswordProtected = ({onLogined}) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");


  const login = () => {
    if (password !== DASHBOARD_PASSWORD) {
      setError("Password incorrect");
      return;
    }
    onLogined();
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) login();
  };

  return (
    <Box justify="center" align="center" width={"full"} pad={'small'} gap="small">
      <Text size="small" style={{ color: "#dc3545" }} textAlign="center">
        {error}
      </Text>
      <Box width="medium" gap="small">
        <TextInput
          placeholder="Password"
          type="password"
          onKeyDown={handleEnter}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Box width="small" alignSelf="center">
          <PrimaryButton
            onClick={login}
            label="Connexion"
            color="#fff"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
