import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
const LineChart = ({ data, title }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title
      },
      datalabels: {
        display: false,
        color: "#707070",
        anchor: "end",
        align: "end",
        offset: 0,
        font: {
          weight: "bold",
        },
      },
    },
  };
  return (
    <Line
      options={options}
      data={data}
      plugins={[ChartDataLabels]}
    />
  );
};

export default LineChart;
